import React from 'react'

import { ProductPage } from '../components'

class Producten extends React.Component {
  render() {
    return <ProductPage />
  }
}

export default Producten
